const CookieC = {
  YEAR: '2023-2024',
  FP_DATA: 'FP_DATA',
  FP_APPLICATION: 'FP_APPLICATION',
  FP_SESSION: 'FP_SESSION',
  FP_STATUS: 'FP_STATUS',
  FP_IDCLIENT: '837648936059- gk1nn2thj1mg0djknsijf88c542d6pnn.apps.googleusercontent.com',
  FP_VERSION: 'FP_VERSION',
  VERSION: '2.1.1',
  TTL: 1800,
   CV_SERVER_URL: 'https://galiocv.myiuc.com',
  //  CV_SERVER_URL: 'http://localhost:3001',
  SERVER_URL: 'https://galioserver.myiuc.com',
   // SERVER_URL: 'http://localhost:3002',
  MEDIA: 'https://igoserver.myiuc.com/media/picture',
  COLOR_PLANNING: {
    'ValidatedAndBilled': "#47AD67",
    'ValidatedButNotBilled': "#B2EAC5",
    'ValidatedPendingBill': "#B2EAC5",
    'PendingValidation': "#FFC96B",
    'NotValidated': "#EB553B",
    'Validated2': "#EB553B",
  }
}

export default CookieC
