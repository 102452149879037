import DataS from "./data.s";

export default class AuthS extends DataS {

 

  login(email, matricule) {
    console.log('hello')
    return this.post('/auth/sign-in', {email, matricule})
  }
  
  register(matricule) {
    return this.post('/auth/sign-up', {matricule})
  }
  
  verifyEmail(email, matricule) {
    console.log(email,matricule)
    return this.post('/auth/email-verification', {email, matricule})
  }
  
  confirmEmail(token) {
    return this.post('/auth/email-confirmation', {token})
  }
  
  codeVerifier(SIGN_CODE, EMPLOYEE_ID) {
    return this.post('/auth/code-verification', {SIGN_CODE, EMPLOYEE_ID})
  }
}
