export const partUser = {
  STATUS: 0,
  PERSONAL: 1,
  FAMILIAL: 2,
  ADDRESS: 3,
  PROFESSIONAL: 4,
  DIPLOMA: 5,
  FINANCIAL: 6,
  FINANCIAL_PAYMENT: 7,
  FINANCIAL_ID: 8,
  FINANCIAL_TRAVEL: 9,
};
