import cookieConstants from '../constants/cookie.c';
import CookieC from '../constants/cookie.c';
import Cookies from 'universal-cookie';

export default class CookieH {
  static getUser = () => {
    return JSON.parse(localStorage.getItem(cookieConstants.FP_DATA));
  };

  static getApplication = () => {
    return new Cookies().get(cookieConstants.FP_APPLICATION);
  };
  static school = (SCH) => {
    new Cookies().set('SCHOOL', SCH);
    new Cookies().set('SERVER', SCH === 'CEFTI' ? cookieConstants.SERVER_CEFTI : cookieConstants.SERVER_URL);
  };
  static getServer = () => {
    return new Cookies().get('SERVER') || cookieConstants.SERVER_URL;
  };
  static getSchoolnAME = () => {
    return new Cookies().get('SCHOOL') || cookieConstants.SERVER_URL;
  };
  static deleteApplication = () => {
    new Cookies().remove(cookieConstants.FP_APPLICATION);
  };

  static getSession = () => {
    return new Cookies().get(cookieConstants.FP_SESSION);
  };

  static clearUserData = () => {
    let cookies = new Cookies();
    cookies.remove(cookieConstants.FP_DATA);
    cookies.remove(cookieConstants.FP_APPLICATION);
    cookies.remove(cookieConstants.FP_STATUS);
    cookies.remove(cookieConstants.FP_SESSION);
    return 1;
  };

  static setApplication = (application) => {
    new Cookies().set(cookieConstants.FP_APPLICATION, application, { path: '/' });
  };
  static setSession = (session) => {
    new Cookies().set(cookieConstants.FP_SESSION, session, { path: '/' });
  };

  static setUser = (data) => {
    localStorage.setItem(CookieC.FP_DATA, JSON.stringify(data));
  };

  static setVersion = () => {
    new Cookies().set(CookieC.FP_VERSION, CookieC.VERSION);
  };

  static getVersion = () => {
    return new Cookies().get(CookieC.FP_VERSION);
  };
}
