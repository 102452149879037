import DataS from "./data.s";

export default class AppS extends DataS {

    create(data) {
        return this.post('/applications', data)
    }

    init(EMPLOYEE_ID, TOTAL_HOURS, SESSION_APPLICATION_ID, SUBJECT_CONSTRAINT_ID) {
        return this.post('/applications/init', {
            EMPLOYEE_ID,
            TOTAL_HOURS,
            SESSION_APPLICATION_ID,
            SUBJECT_CONSTRAINT_ID
        })
    }

    current(EMPLOYEE_ID, SESSION_APPLICATION_ID) {
        return this.get('/applications/current/' + EMPLOYEE_ID + '/' + SESSION_APPLICATION_ID)
    }

    gets(EMPLOYEE_ID) {
        return this.get('/applications/' + EMPLOYEE_ID)
    }

    getAll(EMPLOYEE_ID) {
        return this.get('/applications/all/' + EMPLOYEE_ID)
    }
    getAffectations(EMPLOYEE_ID) {
        return this.get('/applications/affectations/' + EMPLOYEE_ID)
    }

    close(id) {
        return this.get('/applications/close/' + id)
    }
}
