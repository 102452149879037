import DataS from './data.s';

export default class EmployeesS extends DataS {
  getEmployee(id) {
    return this.get('/employees/' + id);
  }

  uploadCV({ FILE, EMPLOYEE_ID }) {
    return this.post('/employees/cv', { FILE, EMPLOYEE_ID });
  }

  closeSign(EMPLOYEE_ID) {
    return this.post('/employees/close-sign', { EMPLOYEE_ID });
  }

  contactUs(
    OBJECT,
    MESSAGE,
    FULLNAME,
    EMAIL,
    EMPLOYEE_ID,
    NUMPHONE) {
    return this.post('/employees/contact-us', {
      OBJECT,
      MESSAGE,
      FULLNAME,
      EMAIL,
      EMPLOYEE_ID,
      NUMPHONE,
    });
  }

  setGmailAccount(EMPLOYEE_ID, GMAIL_ACCOUNT) {
    return this.post('/employees/gmail-account', { GMAIL_ACCOUNT, EMPLOYEE_ID });
  }

  updateFamilial(
    {
      CIVIL_STATUS,
      SPFIRSTNAME,
      SPLASTNAME,
      SPBIRTHDATE,
      CHILDNUM,
      EMERGNUM1,
      EMERGNAME1,
      EMPLOYEE_ID,
    }) {
    console.log(CIVIL_STATUS,
      SPFIRSTNAME,
      SPLASTNAME,
      SPBIRTHDATE,
      CHILDNUM,
      EMERGNUM1,
      EMERGNAME1,
      EMPLOYEE_ID);
    return this.patch('/employees/update/familial', {
      CIVIL_STATUS,
      SPFIRSTNAME,
      SPLASTNAME,
      SPBIRTHDATE,
      CHILDNUM,
      EMERGNUM1,
      EMERGNAME1,
      EMPLOYEE_ID,
    });
  }

  updatePersonnal({ LASTNAME, FIRSTNAME, DENOMINATION, BIRTHPLACE, BIRTHDATE, GENDER, COUNTRY, EMPLOYEE_ID }) {
    return this.patch('/employees/update/personal', {
      LASTNAME,
      FIRSTNAME,
      DENOMINATION,
      BIRTHPLACE,
      BIRTHDATE,
      GENDER,
      COUNTRY,
      EMPLOYEE_ID,
    });
  }

  updateAddress({ NUMPHONE, NUMPHONE2, TOWN, DISTRICT, PRECINCT, EMPLOYEE_ID, ORIGIN_REGION }) {
    return this.patch('/employees/update/address', {
      NUMPHONE,
      NUMPHONE2,
      TOWN,
      DISTRICT,
      PRECINCT,
      ORIGIN_REGION,
      EMPLOYEE_ID,
    });
  }

  updateFinancial({
                    NIU, CNPSYN = false, CNPSNUM, BASESALARY,
                    MINISTERIAL_DECREE,
                    CNPS_LINK,
                    NIU_LINK,
                    PRESENCE_ATTEST, EMPLOYEE_ID,
                  }) {
    return this.patch('/employees/update/financial', {
      NIU, CNPSYN, CNPSNUM, MINISTERIAL_DECREE, CNPS_LINK, NIU_LINK,
      PRESENCE_ATTEST, BASESALARY, EMPLOYEE_ID,
    });
  }

  updateIdentification({
                         IDENTIFICATION,
                         IDENTIFNUM,
                         IDENTIFPLACE,
                         IDENTIFSTART,
                         IDENTIFEND,
                         EMPLOYEE_ID,
                         IDENTITY1,
                         IDENTITY2,
                       }) {
    return this.patch('/employees/update/identification', {
      IDENTIFICATION,
      IDENTIFNUM,
      IDENTIFPLACE,
      IDENTIFSTART,
      IDENTIFEND,
      EMPLOYEE_ID,
      IDENTITY1,
      IDENTITY2,
    });
  }

  updateTravelIdentification(
    {
      IDENTIFICATION_PASSPORT,
      IDENTIFNUM_PASSPORT,
      IDENTIFPLACE_PASSPORT,
      IDENTIFSTART_PASSPORT,
      IDENTIFEND_PASSPORT,
      EMPLOYEE_ID_PASSPORT,
      IDENTITY1_PASSPORT,
      IDENTITY2_PASSPORT,
      EMPLOYEE_ID,
    }) {
    return this.patch('/employees/update/identification_passport', {
      IDENTIFICATION_PASSPORT,
      IDENTIFNUM_PASSPORT,
      IDENTIFPLACE_PASSPORT,
      IDENTIFSTART_PASSPORT,
      IDENTIFEND_PASSPORT,
      EMPLOYEE_ID_PASSPORT,
      IDENTITY1_PASSPORT,
      IDENTITY2_PASSPORT,
      EMPLOYEE_ID,
    });
  }

  updateProfessional({
                       LASTEMPLOYER,
                       LASTJOB,
                       LASTJOBEND,
                       LASTJOBSTART,
                       LASTJOBSTILL,
                       ACTIVITY_PRINCIPAL,
                       EMPLOYEE_ID,
                       SPECIALITY,
                       TITLE,
                       GRADES,
                     }) {
    return this.patch('/employees/update/professional', {
      LASTEMPLOYER,
      LASTJOB,
      LASTJOBEND,
      LASTJOBSTART,
      LASTJOBSTILL,
      ACTIVITY_PRINCIPAL,
      EMPLOYEE_ID,
      GRADES,
      TITLE,
      SPECIALITY,
    });
  }

  updatePaymentMode({
                      PAYMODE,
                      ACCOUNT_BIRTHDATE,
                      ACCOUNT_NUM,
                      RIB_LINK,
                      ACCOUNT_LASTNAME,
                      ACCOUNT_FIRSTNAME, EMPLOYEE_ID,
                    }) {
    return this.patch('/employees/update/pay-mode', {
      PAYMODE,
      ACCOUNT_BIRTHDATE,
      ACCOUNT_NUM,
      RIB_LINK,
      ACCOUNT_LASTNAME,
      ACCOUNT_FIRSTNAME,
      EMPLOYEE_ID,
    });
  }

  updateLastDiploma({ files, EMPLOYEE_ID }) {
    return this.patch('/employees/update/training', {
      files: files.map(e => {
        let obj = { ...e, file: e.base64 };
        delete obj.base64;
        return obj;
      }), EMPLOYEE_ID,
    });
  }

  updateStatus({ STATUS, EMPLOYEE_ID }) {
    return this.patch('/employees/update/status', { STATUS, EMPLOYEE_ID });
  }

  defineLastDiploma(EMPLOYEE_ID, id) {
    return this.patch('/employees/update/diploma', { id, EMPLOYEE_ID });
  }

  removeFile(file, col, EMPLOYEE_ID) {
    return this.patch('/employees/files/remove', { file, col, EMPLOYEE_ID });
  }
}
